<template>
	<div id="detail" v-title :data-title="'三特科技-'+scenic.title">
		<FHeader></FHeader>
		<el-carousel :autoplay="false" indicator-position="none" arrow="never" :height="bannerHeight + 'px'"
			:width="bannerWidth + 'px'">
			<el-carousel-item>
				<img src="../../assets/fImage/detail.png" ref="bannerImg" @load="imgLoad"
					style="width: 1920px; height: 450px;">
			</el-carousel-item>
		</el-carousel>
		<div class="tip"><span>详情介绍</span></div>
		<div class="back">
			<el-page-header content="列表详情" @back="goBack" />
		</div>
		<article>
			<div class="scenicBox">
				<div class="title">
					<div class="left">
						<video 
							v-if="scenic.video!=''&&scenic.video!=null"
							:src="scenic.video" 
							class="scenicVideo"
							autoplay="autoplay" 
							loop="loop" 
							muted="muted" 
							controls="controls"
							controlsList="nodownload">
						</video>
						<img v-else :src="scenic.image" class="scenicImage">
					</div>
					<div class="right">
						<div class="name">{{scenic.title}}</div>
						<div class="level">{{scenic.level}}</div>
						<div class="text">
							<span class="address">{{scenic.address}}</span>
						</div>
						<div class="text">
							<span class="phone">{{scenic.phone}}</span>
						</div>
						<div class="text">
							<span class="link">{{scenic.link}}</span>
						</div>
					</div>
				</div>
				<div class="con_text">
					<v-md-preview :text="scenic.content"></v-md-preview>
				</div>
			</div>
		</article>
		<Footer></Footer>
	</div>
</template>

<script>
	import FHeader from "../../components/f/FHeader.vue"
	import Footer from "../../components/f/Footer.vue"
	import {
		getScenic
	} from "@/api/front";

	export default {
		name: "Index",
		components: {
			FHeader,
			Footer
		},
		data() {
			return {
				scenic: {},
				bannerHeight: '', // 图片父容器高度
				bannerWidth: '', //图片父容器宽度
			}
		},
		created() {
			getScenic(this.$route.query.id).then(data => {
				this.scenic = data.data
				console.log("景区详情",this.scenic)
			})
		},
		beforeRouteUpdate(to, from, next) {
			console.log(to.params)
			next()

		},
		mounted() {
			this.imgLoad();
		},
		methods: {
			imgLoad() {
				this.$nextTick(() => {
					this.bannerHeight = this.$refs.bannerImg.height;
					this.bannerWidth = this.$refs.bannerImg.width;
				})
			},
			goBack() {
				this.$router.push({
					path: "/list_detail/" + this.$route.query.channelId,
				})
			},
		},
	}
</script>

<style lang="scss" scoped>
	#detail {
		.tip {
			position: absolute;
			z-index: 5;
			color: #FFFFFF;
			top: 0;
			width: 100%;
			height: 100px;
			margin: 200px 0 150px 0;
			display: flex;
			justify-content: center;

			span {
				display: block;
				width: 250px;
				height: 100px;
				text-align: center;
				font-size: 40px;
				font-family: Adobe Heiti Std R;
				color: #FFFFFF;
				line-height: 100px;
			}
		}

		.back {
			height: 80px;
			padding: 0 18%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			border-bottom: 1px solid #DBDBDB;

			::v-deep .el-page-header__content {
				font-size: 24px !important;
			}
		}

		.scenicBox {
			padding: 30px 18%;
			.title {
				width: 100%;
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				margin-bottom: 20px;
				.left{
					width: 60%;
					.scenicVideo{
						width: 100%;
					}
				}
				.right{
					margin-left: 30px;
					.name{
						font-size: 36px;
						font-family: Adobe Heiti Std R;
						color: #333333;
						line-height: 60px;
					}
					.level{
						font-size: 24px;
						font-family: Adobe Heiti Std R;
						color: #666666;
						line-height: 60px;
						margin-bottom: 20px;
					}
					.text{
						margin: 15px 0;
						span{
							font-size: 20px;
							font-family: Adobe Heiti Std R;
							color: #666666;
							line-height: 32px;
						}
					}
				}

				.time {
					width: 100%;
					height: 60px;
					font-size: 24px;
					text-align: center;
					font-family: Adobe Heiti Std R;
					color: #666666;
					line-height: 60px;
				}

			}
			.con_text{
				p{
					color: #333333;
					line-height: 50px !important;
				}
				
			}
		}
	}
</style>
